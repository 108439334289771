body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.react-datepicker__time-list {
  padding-left: 0px;
}
.react-datepicker--time-only .react-datepicker__triangle {
  left: 50px;
}
.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
}

.App {
   page-break-before: always;
    page-break-after: always;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
/** Step Wizard */
.step-wizard {
  position: relative;
  min-height: 400px;
}
.step {
  width: 100%;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}
.hide {
  display: none;
}
.steps {
  width: 50%;
  margin: 1rem auto;
}
.steps .steps-content .statBtn {
  display: none;
}
.steps-content {
  margin: 6rem 0rem;
}
.staffs input{
  margin-bottom: 1rem;
}
.ant-list-item-action.listAction {
  margin: 0px;
}
.ant-list-item-action.listAction > li {
  padding: 0px;
  padding-right: 1rem;
}
/* .jira-testing */
.headerImage,
.footerImage {
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
  border-radius: 4px;
  padding: 0.5rem;
}
.headerImage:hover,
.footerImage:hover {
  cursor: pointer;
  border-color: #1890ff;
}
.vitalsTransfer .ant-transfer-list {
  height: 20rem;
  width: 20rem;
}
.vitalsTransfer .ant-transfer-list-content-item span {
  text-transform: capitalize;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 0.7rem;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 0.7rem;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 0.7rem;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 0.7rem;
}
.nfnClinic {
  background-color: gainsboro;
}

@media print {
  body {
    /* margin: 25mm 0mm 25mm 0mm; */
    margin: 0;
    color: #000;
    background: white;
	  font-size: 12pt;
    /* background-color: #fff; */
  }
  @page {
    size: A4;
  }
   .ant-menu, .printBtn {
     display: none;
   }
   .ant-table {
     page-break-inside: avoid;
     page-break-inside: avoid;
     -webkit-column-break-inside: avoid;
             break-inside: avoid;
     page-break-before: always;
     page-break-before: always;
     -webkit-column-break-before: always;
             break-before: always;
   }
}
.viewMedicine .ant-typography p {
  margin: 0rem 0rem 0rem 0rem;
}
div[data-child="true"] {
  color: green;
}
.systemWideReportResp {
  margin: 0.2rem;
}
.label--templateCreate {
  color: lightslategray;
}
